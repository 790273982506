import moment from "moment"

export default {
  purchaseOrder: {
    ID: "new",
    vendorID: null,
    warehouseID: null,
    dueDate: null,
    sentDate: null,
    POStatusID: 1,
    notes: null,
    purchaseOrderDetail: [],
  },
  purchaseOrderDetail: {
    rawItemID: null,
    cost: 0,
    quantity: 0,
    purchaseOrderID: null,
    nonInventoryDescription: null,
  },
  receiverHeader: {
    ID: "new",
    purchaseOrderID: null,
    receiveDate: null,
    status: "open",
    frtVendor: null,
    frtCost: null,
    frtReference: null,
    supplyReference: null,
    receiverDetail: [],
    receiverFreight: [],
  },
  receiverDetail: {
    receiverHeaderID: null,
    rawItemID: null,
    quantity: null,
    cost: null,
    warehouseID: null,
    locationID: null,
    purchaseOrderDetailID: null,
  },
  receiverFreight: {
    receiverHeaderID: null,
    frtVendorID: null,
    frtCost: null,
    frtReference: null,
  },
  finishedProduct: {
    ID: "new",
    name: null,
    lucaGLAcct: null,
    lucaCOGSGL: null,
    lucaSalesGL: null,
    PSLucaInvGLAcct: null,
    PSLucaCOGSGL: null,
    PSLucaSalesGL: null,
    releaseDays: null,
    minimum: null,
    bulkItem: false,
    coatingThickness: null,
    useFlatRateMarkup: null,
    allowProduction: true,
    recipeDetail: [],
  },
  recipeDetail: {
    rawItemID: null,
    quantity: 0,
    finishedProductID: null,
  },
  productionOrder: {
    ID: "new",
    finishedProductID: null,
    userID: null,
    dueDate: null,
    quantity: null,
    machineID: null,
    productionOrderStatusID: 1,
    batch: [],
  },
  batch: {
    productionOrderID: null,
    productionDate: null,
    shift: null,
    quantity: null,
    runTime: null,
  },
  finishedInventory: {
    finishedProductID: null,
    locationID: null,
    quantity: null,
    cost: null,
  },
  productionLog: {
    finishedProductID: null,
    batchID: null,
    locationID: null,
    quantity: null,
    appliedOverheadRate: null,
    byProduct: null,
    bagID: null,
  },
  sample: {
    ID: "new",
    pursellSampleID: null,
    batchID: null,
    description: "",
    sampleTest: [],
    sampleStatusID: 1,
    subStatus: "In-Progress",
    confirmedShipping: false,
    locationID: null,
    sampleCreatedDateTime: null,
  },
  sampleTest: {
    sampleID: null,
    scheduledDays: null,
    testDate: null,
    testResult: null,
  },
  salesOrder: {
    ID: "new",
    customerID: null,
    shipToID: null,
    orderDate: null,
    dueDate: null,
    referenceNumber: "",
    salesOrderStatusID: 1,
    salesOrderTypeID: 1,
    warehouseID: null,
    salesOrderDetail: [],
  },
  salesOrderDetail: {
    salesOrderID: null,
    finishedProductID: null,
    quantity: null,
    unitPrice: null,
    totalItemPrice: null,
  },
  load: {
    ID: "new",
    freightVendorID: null,
    vendorReferenceNumber: null,
    scheduledDate: null,
    loadStatusID: 1,
    freightCost: null,
    salesOrderID: null,
    customerPaidFreight: true,
    specialInstructions: null,
    loadDetail: [],
    loadFreight: [],
  },
  loadDetail: {
    loadID: null,
    salesOrderDetailID: null,
    finishedProductID: null,
    finishedInventoryID: null,
    bulkItem: null,
    quantity: null,
    unitCost: null,
    unitPrice: null,
  },
  loadFreight: {
    loadID: null,
    freightCost: null,
    freightVendorID: null,
    freightReference: "",
  },
  inventoryAdjustment: {
    ID: "new",
    type: null,
    adjustmentAccount: "pia",
    description: null,
    completed: false,
    updatedDate: null,
    updatedBy: null,
    inventoryAdjustmentDetail: [],
  },
  inventoryAdjustmentDetail: {
    inventoryAdjustmentID: null,
    rawItemID: null,
    finishedInventoryID: null,
    finishedProductID: null,
    adjustQuantityBy: 0,
    adjustCostBy: 0,
    adjustValueBy: 0,
    adjustLocationID: null,
    ownedByCompanyID: null,
  },
  inventoryTransfer: {
    ID: "new",
    status: "Setup",
    completed: false,
    updatedDate: null,
    updatedBy: null,
    inventoryTransferDetail: [],
  },
  inventoryTransferDetail: {
    inventoryTransferID: null,
  },
  bill: {
    ID: "new",
    vendorID: null,
    refNumber: null,
    billDate: moment(),
    billDueDate: moment(),
    memo: null,
    lucaTransactionCreated: null,
    complete: null,
    billDetail: [],
  },
  billDetail: {
    billID: null,
    companyID: null,
    accountID: null,
    amount: null,
    memo: null,
    jobID: null,
  },
  customerProfile: {
    ID: "new",
    customerID: null,
    terms: null,
    notes: null,
    customerSegmentID: null,
    salespersonID: null
  },
  conversionJob: {
    ID: "new",
    consumedFinishedProductID: null,
    producedFinishedProductID: null,
    jobTypeID: 2,
    completed: false,
    updatedDate: null,
    updatedBy: null,
    conversionJobDetail: [],
  },
  conversionJobDetail: {
    conversionJobID: null,
    finishedIventoryID: null,
  },
  creditMemo: {
    ID: "new",
    salesOrderStatusID: 1,
    creditMemoDetail: [],
  },
  creditMemoDetail: {
    creditMemoID: null,
  },
  salesUnitOfMeasure: {
    ID: "new",
  },
  physicalInventory: {
    ID: "new",
    physicalInventoryDetail: [],
  },
  physicalInventoryDetail: [],
  blending: {
    ID: "new",
    productionOrderStatusID: 1,
    blendingProduct: [],
    blendingProduction: [],
    blendingRawConsumption: [],
  },
  blendingProduct: {
    blendingID: null,
    blendingConsumption: [],
  },
  blendingConsumption: {
    blendingProductID: null,
  },
  blendingProduction: {
    blendingID: null,
  },
  blendingRawConsumption: {
    blendingID: null,
  },
  customerCategory: {
    ID: "new"
  },
  customerSegment: {
    ID: "new"
  },
  productSegment: {
    ID: "new"
  },
  Salesperson: {
    ID: "new"
  },
  rawItem: {
    ID: "new"
  }
}
